<template>
  <transition
    mode="out-in"
    enter-active-class="animate__animated animate__faster animate__backInUp"
    leave-active-class="animate__animated animate__faster animate__backOutDown"
  >
    <div
      class="w-full h-full z-[9999] top-0 fixed left-0 right-0 text-center max-h-screen py-3 overflow-y-scroll flex justify-center justify-items-center items-center"
      v-if="showLightBox"
    >
      <button @click="showLightBox = false">
        <fa-icon
          icon="fa-close"
          class="text-white absolute top-5 right-5 text-5xl z-50"
        />
      </button>
      <splide
        focus="center"
        :has-track="false"
        :options="splideOptions"
        class="max-h-screen !py-0"
      >
        <splide-track>
          <splide-slide
            v-for="image of selectedImages"
            :key="image.src"
            class="max-h-screen w-full"
          >
            <img :src="image.src" class="max-h-screen mx-auto justify-center" />
          </splide-slide>
        </splide-track>
      </splide>
    </div>
  </transition>
  <transition
    mode="out-in"
    enter-active-class="animate__animated animate__faster animate__fadeIn"
    leave-active-class="animate__animated animate__faster animate__fadeOut"
  >
    <div
      v-if="showLightBox"
      @click="showLightBox = false"
      class="bg-black/60 fixed top-0 bottom-0 right-0 left-0 z-[9997]"
    ></div>
  </transition>
</template>

<script>
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css/sea-green";
import { mapWritableState } from "pinia";
import { useListingStore } from "@/store/listing";

export default {
  name: "LightBox",
  components: { Splide, SplideSlide, SplideTrack },
  props: ["images"],
  data: () => ({
    imageIndex: -1,
    open: false,
  }),
  computed: {
    ...mapWritableState(useListingStore, [
      "showLightBox",
      "selectedImages",
      "selectedImageIndex",
    ]),
    splideOptions() {
      return {
        start: this.selectedImageIndex,
        rewind: true,
        paginationKeyboard: true,
      };
    },
  },
  mounted() {},
  methods: {
    openModal(imageIndex) {
      this.open = true;
      this.imageIndex = imageIndex;
    },
  },
};
</script>

<style scoped></style>
