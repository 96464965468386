import { createRouter, createWebHistory } from "vue-router";
import ListingsView from "@/views/ListingsView";

const routes = [
  {
    path: "/",
    name: "home",
    component: ListingsView,
  },
  {
    path: "/listings",
    name: "listings",
    component: ListingsView,
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: ListingsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
