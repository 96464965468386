<template>
  <modal-component ref="modal" @close="showSignUpModal = false">
    <div class="w-full p-3">
      <fa-icon
        icon="fa-times"
        class="text-3xl absolute right-5 top-5 cursor-pointer"
        @click="showSignUpModal = false"
      />
      <div
        class="w-full text-center flex items-center align-items-center justify-center"
      >
        <img src="@/assets/allhouse.svg" class="w-[200px] my-5" />
      </div>
      <form @submit.prevent="signup">
        <div class="w-full text-left text-xl">
          <label> Full Name </label>
          <input
            v-model="fullName"
            type="text"
            required
            class="w-full border-gray-200 border-2 my-2 rounded-md outline-none py-2 px-3"
          />
        </div>
        <div class="w-full text-left text-xl">
          <label> Phone </label>
          <input
            v-model="phone"
            type="text"
            required
            class="w-full border-gray-200 border-2 my-2 rounded-md outline-none py-2 px-3"
          />
        </div>
        <div class="w-full text-left text-xl">
          <label> Email </label>
          <input
            v-model="username"
            type="email"
            required
            class="w-full border-gray-200 border-2 my-2 rounded-md outline-none py-2 px-3"
          />
        </div>
        <div class="w-full text-left text-xl">
          <label> Password </label>
          <input
            v-model="password"
            type="password"
            required
            class="w-full border-gray-200 border-2 my-2 rounded-md outline-none py-2 px-3"
          />
        </div>
        <div class="w-full text-left text-xl">
          <button
            class="bg-black text-white text-xl text-center py-2 px-3 uppercase w-full my-3"
          >
            Create Account
          </button>
        </div>
        <div class="w-full text-left text-xl">
          <button
            class="bg-gray-200 text-black text-xl text-center py-2 px-3 uppercase w-full my-3"
            @click="showSignUpModal = false"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/Modal";
import { mapWritableState } from "pinia";
import { useSessionStore } from "@/store/session";
import { toast } from "vue3-toastify";
export default {
  name: "SignUpModal",
  components: { ModalComponent },
  data: () => ({
    fullName: "",
    phone: "",
    username: null,
    password: null,
  }),
  computed: {
    ...mapWritableState(useSessionStore, ["showSignUpModal"]),
  },
  methods: {
    async signup() {
      const { username, password, fullName, phone } = this;
      const result = await useSessionStore().signup({
        name: fullName,
        email: username,
        password,
        passwordRepeat: password,
        phone,
      });
      if (!result) return;
      const { userId, token } = result;
      useSessionStore().$patch({
        userId,
        userToken: token,
      });
      localStorage.setItem("userToken", token);
      localStorage.setItem("userId", userId);
      this.showSignUpModal = false;
      toast.success("Account creation successful");
      this.$gtag.event("signup", {
        event_category: "accounts",
        event_label: "New user registered",
        value: this.username,
      });
    },
  },
  watch: {
    showSignUpModal(newVal) {
      this.$refs.modal.open = newVal;
    },
  },
};
</script>

<style scoped></style>
