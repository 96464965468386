<template>
  <transition
    mode="out-in"
    enter-active-class="animate__animated animate__faster animate__backInUp"
    leave-active-class="animate__animated animate__faster animate__backOutDown"
  >
    <div
      :class="`bg-white w-full md:w-${modalWidth} top-0 md:mx-auto z-50 fixed left-0 right-0 text-center max-h-full overflow-y-scroll`"
      v-if="open"
    >
      <slot></slot>
    </div>
  </transition>
  <transition
    mode="out-in"
    enter-active-class="animate__animated animate__faster animate__fadeIn"
    leave-active-class="animate__animated animate__faster animate__fadeOut"
  >
    <div
      v-if="open"
      @click="open = false"
      class="bg-black/60 fixed top-0 bottom-0 right-0 left-0 z-40"
    ></div>
  </transition>
</template>

<script>
export default {
  name: "ModalComponent",
  data: () => ({
    open: false,
  }),
  props: ["size"],
  computed: {
    modalWidth() {
      if (!this.size) return "1/3";
      switch (this.size) {
        case "sm":
          return "1/3";
        case "md":
          return "2/3";
        case "lg":
          return "4/5";
        case "fullscreen":
          return "w-full";
        default:
          return "1/3";
      }
    },
  },
  watch: {
    open(newVal, oldVal) {
      if (!newVal && oldVal) this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
