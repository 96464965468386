import { createApp } from "vue";
import pinia from "@/store/initPinia";
import App from "./App.vue";
import "./utils/FontSetup";
import "animate.css";
import "./index.css";
import "./registerServiceWorker";
import router from "./router";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueGtag from "vue-gtag";

const app = createApp(App);
app.component("fa-icon", FontAwesomeIcon);
app.use(pinia);

app.use(router);
app.use(Vue3Toastify, {
  style: {
    "z-index": 9999,
    position: "fixed",
  },
});
app.use(VueGtag, {
  config: {
    id: "G-BWCNY3ZXW7",
    params: {
      anonymize_ip: true,
    },
  },
});
app.mount("#app");
