import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faMinus,
  faPlus,
  faCheck,
  faCaretDown,
  faCircleCheck,
  faClose,
  faEdit,
  faCircleUser,
  faGift,
  faCoffee,
  faArrowLeftLong,
  faArrowRightLong,
  faTrash,
  faSpinner,
  faSearch,
  faLocation,
  faMapPin,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleDot, faCircle } from "@fortawesome/free-regular-svg-icons";

library.add(
  faLocation,
  faMapPin,
  faLocationDot,
  faSearch,
  faUserSecret,
  faMinus,
  faPlus,
  faCheck,
  faCaretDown,
  faCircleCheck,
  faClose,
  faCircle,
  faCircleDot,
  faEdit,
  faCircleUser,
  faGift,
  faCoffee,
  faArrowLeftLong,
  faArrowRightLong,
  faTrash,
  faSpinner
);
