<template>
  <div class="w-full">
    <div class="w-full">
      <div class="w-full">
        <div
          class="images-reel"
          v-b-tooltip.hover
          title="Click to view gallery"
        >
          <div
            :style="`width: 200px;height: 200px;background-image:url('${
              image.urlThumbnailMedium || image.url
            }');`"
            class="image-tile shadow-xl"
            v-for="(image, imageIndex) of selectedListing.images"
            :key="image._id"
            @click="onImageClick(imageIndex)"
          ></div>
        </div>
      </div>
      <div class="w-full py-2">

        <a
            v-if="selectedListing.location.coordinates"
          class="text-center text-2xl font-bold"
          target="_blank"
          :href="`https://www.google.com/maps/place/${googleAddress.replaceAll(
            ' ',
            '+'
          )}/@${selectedListing.location.coordinates.latitude},${
            selectedListing.location.coordinates.longitude
          },20z`"
        >
          {{ selectedListing.location.address.address }}
          <fa-icon icon="fa-location-dot" class="ml-1" />
        </a>
        <div v-else>
          {{ selectedListing.location.address.address }}
        </div>
      </div>
      <span class="text-xl py-2 w-full text-center"
        >${{ selectedListing.description.rent }}p/w</span
      >
      <br />
      <br />


      <div class="w-full grid grid-cols-4 md:grid-cols-4 py-3">
        <div class="flex justify-center">
          <h4 class="text-center">
            <img
              src="@/assets/icons/bed.png"
              style="width: 32px; margin-right: 0px"
            />
          </h4>
        </div>
        <div class="flex justify-center">
          <h4 class="text-center">
            <img
              src="@/assets/icons/Icon1.png"
              style="width: 32px; margin-right: 0px"
            />
          </h4>
        </div>
        <div class="flex justify-center">
          <h4 class="text-center">
            <img
              src="@/assets/icons/car.svg"
              style="width: 32px; margin-right: 0px"
            />
          </h4>
        </div>
        <div class="flex justify-center">
          <h4 class="text-center">
            <img
              src="@/assets/icons/icon-calendar.png"
              style="width: 32px; margin-right: 0px"
            />
          </h4>
        </div>
        <div class="flex justify-center">
          <h4 class="text-center">
            {{ selectedListing.description.bedrooms }}
          </h4>
        </div>
        <div class="flex justify-center">
          <h4 class="text-center">
            {{ selectedListing.description.bathrooms }}
          </h4>
        </div>
        <div class="flex justify-center">
          <h4 class="text-center">
            {{ selectedListing.description.carparks }}
          </h4>
        </div>
        <div class="flex justify-center">
          <h4 class="text-center">
            {{
              moment(selectedListing.advertising.availableAt).format("DD MMM")
            }}
          </h4>
        </div>
      </div>

      <br />
      <div class="w-full">
        <pre class="w-full whitespace-pre-wrap text-left font-gothic">{{
          selectedListing.advertising.description
        }}</pre>

        <!--        <div class="w-100" v-if="selectedListing.description.amenities">-->
        <!--          <strong>Amenities</strong>-->
        <!--          <p>{{ selectedListing.description.amenities }}</p>-->
        <!--          <hr />-->
        <!--        </div>-->
        <!--        <div-->
        <!--          class="w-100"-->
        <!--          v-if="selectedListing.description.carParkDescription"-->
        <!--        >-->
        <!--          <strong>Car Parks Info</strong>-->
        <!--          <p>{{ selectedListing.description.carParkDescription }}</p>-->
        <!--          <hr />-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapWritableState } from "pinia";
import { useListingStore } from "@/store/listing";

export default {
  name: "description-page",
  props: ["selectedListing"],
  components: {},
  data: () => ({
    index: null,
  }),
  computed: {
    ...mapWritableState(useListingStore, [
      "selectedImageIndex",
      "selectedImages",
      "showLightBox",
    ]),
    lightboxImages() {
      return this.selectedListing.images.map((i) => ({
        src: i.url,
      }));
    },
    googleAddress() {
      return `${this.selectedListing.location.formattedAddress
        .split("/")
        .slice(-1)
        .pop()}`;
    },
  },
  methods: {
    moment,
    onImageClick(imageIndex) {
      this.selectedImageIndex = imageIndex;
      this.selectedImages = this.lightboxImages;
      this.showLightBox = true;
    },
  },
};
</script>

<style scoped>
.carousel-sld > img {
  width: auto !important;
}

.images-reel {
  width: 100%;
  overflow: scroll;
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.image-tile {
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  min-width: 200px;
  margin-right: 10px;
  cursor: pointer;
}
</style>
