import axios from "axios";
import { API_URL, GENERAL_ERROR } from "@/utils/constants";
import { toast } from "vue3-toastify";
import { useAppStore } from "@/store/app";

export const actions = {
  async authenticate({ username, password }) {
    useAppStore().$patch({
      loading: true,
    });
    try {
      const {
        data: { token, userId },
      } = await axios({
        method: "POST",
        url: `${API_URL}/login`,
        data: {
          username,
          password,
        },
      });
      useAppStore().$patch({
        loading: false,
      });
      return {
        token,
        userId,
      };
    } catch ({ response }) {
      console.log(response);
      toast.error(
        response && response.data
          ? response.data.message || response.data
          : GENERAL_ERROR
      );
    }
    useAppStore().$patch({
      loading: false,
    });
  },
  async addFavorite(listing) {
    if (!this.userFavorites.includes(listing)) {
      this.userFavorites.push(listing);
    }
    console.log("user favorites from actions: ", this.userFavorites);
  },
  removeFavorite(listing) {
    this.userFavorites = this.userFavorites.filter(list => list !== listing);
  },
  async signup({ name, email, password, passwordRepeat, phone }) {
    useAppStore().$patch({
      loading: true,
    });
    try {
      const {
        data: { token, userId },
      } = await axios({
        url: `${API_URL}/signup`,
        method: "post",
        data: {
          firstName: name.split(" ")[0],
          lastName: name.split(" ").slice(1).join(" ") || "unknown",
          email,
          phone,
          password,
          passwordRepeat,
        },
      });
      useAppStore().$patch({
        loading: false,
      });
      return {
        userId,
        token,
      };
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
    useAppStore().$patch({
      loading: false,
    });
  },
  async requestPasswordReset({ email }) {
    useAppStore().$patch({
      loading: true,
    });
    try {
      await axios({
        url: `${API_URL}/forgot-password`,
        method: "post",
        data: {
          email,
        },
      });
      useAppStore().$patch({
        loading: false,
      });
      return true;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
    useAppStore().$patch({
      loading: false,
    });
  },
  async passwordReset({ resetToken, newPassword }) {
    useAppStore().$patch({
      loading: true,
    });
    try {
      const {
        data: { token, userId },
      } = await axios({
        url: `${API_URL}/change-password`,
        method: "post",
        data: {
          code: resetToken,
          password: newPassword,
        },
      });
      useAppStore().$patch({
        loading: false,
      });
      return {
        userId,
        token,
      };
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
    useAppStore().$patch({
      loading: false,
    });
  },
  async changePassword({ currentPassword, newPassword, confirmPassword }) {
    useAppStore().$patch({
      loading: true,
    });
    try {
      const { data } = await axios({
        url: `${API_URL}/api/me/change-password`,
        method: "post",
        data: {
          currentPassword,
          newPassword,
          confirmPassword,
        },
        headers: {
          Authorization: `Bearer ${this.userToken}`,
        },
      });
      useAppStore().$patch({
        loading: false,
      });
      return data;
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
    useAppStore().$patch({
      loading: false,
    });
  },
  async getUserInfo() {
    useAppStore().$patch({
      loading: true,
    });
    try {
      const { userToken, userId, guestToken, guestId } = this;
      let options = {
        populate: { promoCode: true },
      };
      const {
        data: { guest, user },
      } = await axios({
        url: `${API_URL}/${userToken ? "api/users" : "api/guests"}/${
          userToken ? userId : guestId
        }?options=${JSON.stringify(options)}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      this.$patch({
        guestInfo: guest,
        userInfo: user,
      });
    } catch ({ response }) {
      toast.error(
        response && response.data && response.data.message
          ? response.data.message
          : GENERAL_ERROR
      );
    }
    useAppStore().$patch({
      loading: false,
    });
  },
};
