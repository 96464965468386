<template>
  <div class="loader-background" v-bind:class="{ hidden: !loading }">
    <div>
      <img src="@/assets/icons/Allhouse_LoadingGIF(1).gif" />
      <h5>Loading...</h5>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useAppStore } from "@/store/app";

export default {
  name: "LoaderWidget",
  computed: {
    ...mapState(useAppStore, ["loading"]),
  },
};
</script>

<style scoped>
.loader-background {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999998;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: visibility 0s, opacity 0.5s linear;
}

img {
  width: 80px;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.loader-background > :first-child {
  max-width: 200px;
  text-align: center;
  background-color: white;
  padding: 30px 40px;
  border-radius: 50%;
  display: block;
}
</style>
