import { defineStore } from "pinia";
import { actions } from "./actions";
import { getters } from "./getters";

export const useAppStore = defineStore({
  id: "app",
  state: () => ({
    loading: false,
  }),
  getters,
  actions,
});
