<template>
  <div
    @click="open = !open"
    class="bg-white border-[1px] border-black py-2 px-3 text-secondary-button relative hover:cursor-pointer rounded-md"
  >
    {{ currentSelection }}
    <!-- <fa-icon icon="fa-caret-down" class="absolute right-5" /> -->
  </div>

  <transition
  >

    <div
    class="z-50 dropdown-menu fixed bg-white border border-gray-300 mt-1 rounded-md w-2/7 overflow-hidden "      
    v-if="open"
    >
      <div class="w-full flex-none">
        <h3 class="text-highlight font-bold px-3">
          {{ selectInstruction }}
        </h3>
      </div>
      <div class="w-full overflow-scroll grow">
        <div
          v-for="option of options"
          :key="option"
          class="w-full grid grid-cols-5  hover:bg-gray-200 px-3 cursor-pointer"
          :class="{ 'bg-ahgreen': isSelected(option) }"
          @click="selectOption(option)"
        >
          <div class="text-left col-span-4 ">
            {{ textBy ? option[textBy] : option }}
          </div>
          <!-- <div
            class="col-span-1 text-green-500 flex justify-end items-end justify-items-end"
          >
            <fa-icon
              icon="fa-circle-check"
              v-if="isSelected(option)"
              class="text-2xl"
            />
          </div> -->
        </div>
      </div>
    </div>
    </transition>
    <transition>
      <div v-if="open" @click="open = false" class="overlay fixed top-0 bottom-0 right-0 left-0 z-40"></div>
    </transition>
    

  
  

</template>

<script>
export default {
  name: "SelectDropdown",
  props: {
    modelValue: [Object, Array, String, Number, null],
    multiple: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
      default: null,
    },
    textBy: {
      type: String,
      default: null,
    },
    options: [Array],
    noSelectionLabel: {
      type: String,
      default: "Please select an option",
    },
    maxSelection: {
      type: Number,
      default: 0,
    },
    minSelection: {
      type: Number,
      default: 1,
    },
  },
  emits: ["update:modelValue"],
  data: () => ({
    open: false,
  }),
  computed: {
    selectInstruction() {
      if (!this.multiple) return "";
      else {
        return "";
      }
    },
    currentSelection() {
      if (
        (this.value && !this.multiple) ||
        (this.value && this.multiple && this.value.length > 0)
      ) {
        if (this.multiple) {
          return `${this.value.length} 
           selected`;
        } else {
          return this.textBy ? this.value[this.textBy] : this.value;
        }
      } else {
        return this.noSelectionLabel;
      }
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    isSelected(option) {
      if (this.multiple) {
        return this.modelValue.find((v) =>
          this.trackBy ? v[this.trackBy] === option[this.trackBy] : v === option
        );
      } else {
        return this.trackBy
          ? this.modelValue[this.trackBy] === option[this.trackBy]
          : this.modelValue === option;
      }
    },
    selectOption(option) {
      if (this.multiple) {
        let value = this.isSelected(option)
          ? this.value.filter((v) =>
              this.trackBy
                ? v[this.trackBy] !== option[this.trackBy]
                : v !== option
            )
          : [...this.value, option];
        console.log(value.length);
        if (this.maxSelection && value.length > this.maxSelection) {
          value.shift();
        }
        this.value = value;
      } else {
        this.value = option;
        this.open = false;
      }
    },
  },
};
</script>

<style scoped>
.popup{
  border-radius:1rem;
}
.bg-ahgreen{
  background-color:#b6dfb8;
}
.dropdown-menu{
  position:absolute;
  max-height: 200px; 
  overflow-y: auto; 
  z-index: 50;
}
.overlay{
  z-index:40;
}
</style>
