<template>
  <modal-component ref="modal" @close="showAuthModal = false" size="lg">
    <div class="w-full p-3">
      <fa-icon
        icon="fa-times"
        class="text-3xl absolute right-5 top-5 cursor-pointer"
        @click="showAuthModal = false"
      />
      <div
        class="w-full text-center flex items-center align-items-center justify-center"
      >
        <img src="@/assets/allhouse.svg" class="w-[200px] my-5" />
      </div>
      <form @submit.prevent="login">
        <div class="w-full text-left text-xl">
          <label> Email </label>
          <input
            v-model="username"
            type="email"
            required
            class="w-full border-gray-200 border-2 my-2 rounded outline-none py-2 px-3"
          />
        </div>
        <div class="w-full text-left text-xl">
          <label> Password </label>
          <input
            v-model="password"
            type="password"
            required
            class="w-full border-gray-200 border-2 my-2 rounded outline-none py-2 px-3"
          />
        </div>
        <div class="w-full text-left text-xl">
          <button
            class="bg-green text-dark text-xl text-center py-2 px-3 uppercase w-full my-3 rounded"
          >
            Sign In
          </button>
        </div>
      </form>
      <div class="flex w-full my-5">
        <div class="flex grow justify-items-center justify-center flex-col">
          <div class="border-b-2 border-b-gray-400 w-full h-[2px]"></div>
        </div>
        <div class="px-3">OR</div>
        <div class="flex grow justify-items-center justify-center flex-col">
          <div class="border-b-2 border-b-gray-400 w-full h-[2px]"></div>
        </div>
      </div>
      <div class="w-full text-left text-xl">
        <button
          class="bg-gray-200 hover:bg-gray-300 text-black text-xl text-center py-2 px-3 uppercase w-full my-3 rounded"
          @click="onSignUp"
        >
          Create Account
        </button>
      </div>
      <div class="w-full text-left text-xl">
        <button
          class="bg-white border-black border-2 text-black text-xl text-center py-2 px-3 uppercase w-full my-3 rounded hover:bg-black hover:text-white"
          @click="onForgotPassword"
        >
          Forgot Password?
        </button>
      </div>
    </div>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/Modal";
import { mapWritableState } from "pinia";
import { useSessionStore } from "@/store/session";
import { toast } from "vue3-toastify";
export default {
  name: "AuthModal",
  components: { ModalComponent },
  data: () => ({
    username: null,
    password: null,
  }),
  computed: {
    ...mapWritableState(useSessionStore, [
      "showAuthModal",
      "showSignUpModal",
      "showForgotPasswordModal",
    ]),
  },
  methods: {
    onSignUp() {
      this.showAuthModal = false;
      this.showSignUpModal = true;
    },
    onForgotPassword() {
      this.showAuthModal = false;
      this.showForgotPasswordModal = true;
    },
    async login() {
      const { username, password } = this;
      const result = await useSessionStore().authenticate({
        username,
        password,
      });
      if (!result) return;
      const { userId, token } = result;
      useSessionStore().$patch({
        userId,
        userToken: token,
      });
      localStorage.setItem("userToken", token);
      localStorage.setItem("userId", userId);
      this.showAuthModal = false;
      toast.success("Login successful, Welcome back to Allhouse");
      this.$gtag.event("login", { event_category: "accounts" });
    },
  },
  watch: {
    showAuthModal(newVal) {
      this.$refs.modal.open = newVal;
    },
  },
};
</script>

<style scoped>
.bg-green{
  background-color:#a7d9aa;
}
.rounded{
  border-radius:2rem;
}
</style>
