<template>
  <modal-component ref="modal" @close="showForgotPasswordModal = false">
    <div class="w-full p-3">
      <fa-icon
        icon="fa-times"
        class="text-3xl absolute right-5 top-5 cursor-pointer"
        @click="showForgotPasswordModal = false"
      />
      <div
        class="w-full text-center flex items-center align-items-center justify-center"
      >
        <img src="@/assets/allhouse.svg" class="w-[200px] my-5" />
      </div>
      <form @submit.prevent="requestPasswordReset">
        <div class="w-full text-left text-xl">
          <label> Email </label>
          <input
            v-model="username"
            type="email"
            required
            class="w-full border-gray-200 border-2 my-2 rounded-md outline-none py-2 px-3"
          />
        </div>
        <div class="w-full text-left text-xl">
          <button
            class="bg-black text-white text-xl text-center py-2 px-3 uppercase w-full my-3"
          >
            Request Password Reset
          </button>
        </div>
      </form>
      <div class="w-full text-left text-xl">
        <button
          class="bg-gray-200 text-black text-xl text-center py-2 px-3 uppercase w-full my-3"
          @click="showForgotPasswordModal = false"
        >
          Cancel
        </button>
      </div>
    </div>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/Modal";
import { mapWritableState } from "pinia";
import { useSessionStore } from "@/store/session";
import { toast } from "vue3-toastify";
export default {
  name: "ForgotPasswordModal",
  components: { ModalComponent },
  data: () => ({
    username: null,
  }),
  computed: {
    ...mapWritableState(useSessionStore, ["showForgotPasswordModal"]),
  },
  methods: {
    async requestPasswordReset() {
      const { username } = this;
      await useSessionStore().requestPasswordReset({
        email: username,
      });
      this.showForgotPasswordModal = false;
      toast.success(
        "Password reset request sent, if your email exist in Allhouse's database and email will be sent shortly with the steps to follow"
      );
    },
  },
  watch: {
    showForgotPasswordModal(newVal) {
      this.$refs.modal.open = newVal;
    },
  },
};
</script>

<style scoped></style>
