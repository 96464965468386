<template>
  <div id="app" class="h-screen w-screen bg-main font-main font-gothic">
    <nav-bar />
    <div class="w-full h-[calc(100%-70px)] bg-main overflow-hidden">
      <router-view v-slot="{ Component, route }">
        <transition
          mode="out-in"
          :enter-active-class="route.meta.enterClass"
          :leave-active-class="route.meta.leaveClass"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <listing-modal />
    <light-box />
    <auth-modal />
    <sign-up-modal />
    <forgot-password-modal />
    <password-recovery-modal />
    <loader-widget />
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import { useListingStore } from "@/store/listing";
import ListingModal from "@/components/ListingModal";
import LightBox from "@/components/LightBox";
import AuthModal from "@/components/AuthModal";
import { mapWritableState } from "pinia";
import SignUpModal from "@/components/SignUpModal";
import ForgotPasswordModal from "@/components/ForgotPasswordModal";
import { useSessionStore } from "@/store/session";
import PasswordRecoveryModal from "@/components/PasswordRecoveryModal";
import LoaderWidget from "@/components/Loader";
export default {
  name: "App",
  components: {
    PasswordRecoveryModal,
    ForgotPasswordModal,
    SignUpModal,
    AuthModal,
    ListingModal,
    NavBar,
    LightBox,
    LoaderWidget,
  },
  computed: {
    ...mapWritableState(useListingStore, ["selectedListing"]),
    ...mapWritableState(useSessionStore, ["showPasswordRecoveryModal"]),
  },
  async mounted() {
    await useListingStore().getRegions();
    await useListingStore().getLatestListings();
    await useListingStore().getCoolestPaths();
    await useListingStore().getTrendingListings();
    await useListingStore().getPetFriendly();
    if (this.$route.query) {
      if (this.$route.query.id)
        this.selectedListing = await useListingStore().getListing({
          listingId: this.$route.query.id,
        });
      if (this.$route.query.code) this.showPasswordRecoveryModal = true;
    }
  },
  watch: {
    selectedListing(newVal) {
      this.$router.push(
        newVal
          ? {
              query: {
                id: newVal._id,
              },
            }
          : {}
      );
    },
  },
};
</script>
