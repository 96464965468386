import { defineStore } from "pinia";
import { actions } from "./actions";
import { getters } from "./getters";

export const useListingStore = defineStore({
  id: "listing",
  state: () => ({
    showSearchResults: false,
    selectedListing: null,
    searchResults: [],
    latestListings: [],
    coolestPads: [],
    trendingProperties: [],
    petFriendly:[],
    petSelection:"",
    viewingTimes: null,
    regions: [],
    selectedRegion: null,
    selectedDistrict: null,
    selectedSuburbs: [],
    priceRange: [100, 900],
    bedrooms: 0,
    bathrooms: 0,
    selectedImages: [],
    selectedImageIndex: 0,
    showLightBox: false,
  }),
  getters,
  actions,
});
