<template>
  <modal-component ref="modal" @close="showPasswordRecoveryModal = false">
    <div class="w-full p-3">
      <fa-icon
        icon="fa-times"
        class="text-3xl absolute right-5 top-5 cursor-pointer"
        @click="showPasswordRecoveryModal = false"
      />
      <div
        class="w-full text-center flex items-center align-items-center justify-center"
      >
        <img src="@/assets/allhouse.svg" class="w-[200px] my-5" />
      </div>
      <form @submit.prevent="recoverPassword">
        <div class="w-full text-left text-xl">
          <label>New Password </label>
          <input
            v-model="password"
            type="password"
            required
            class="w-full border-gray-200 border-2 my-2 rounded-md outline-none py-2 px-3"
          />
        </div>
        <div class="w-full text-left text-xl">
          <label> Confirm Password </label>
          <input
            v-model="confirmPassword"
            type="password"
            required
            class="w-full border-gray-200 border-2 my-2 rounded-md outline-none py-2 px-3"
          />
        </div>
        <div class="w-full text-left text-xl">
          <button
            class="bg-black text-white text-xl text-center py-2 px-3 uppercase w-full my-3"
          >
            Update Password
          </button>
        </div>
        <div class="w-full text-left text-xl">
          <button
            class="bg-gray-200 text-black text-xl text-center py-2 px-3 uppercase w-full my-3"
            @click="showForgotPasswordModal = false"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </modal-component>
</template>

<script>
import ModalComponent from "@/components/Modal";
import { mapWritableState } from "pinia";
import { useSessionStore } from "@/store/session";
import { toast } from "vue3-toastify";
export default {
  name: "PasswordRecoveryModal",
  components: { ModalComponent },
  data: () => ({
    username: null,
    password: null,
  }),
  computed: {
    ...mapWritableState(useSessionStore, [
      "showPasswordRecoveryModal",
      "showAuthModal",
      "showForgotPasswordModal",
    ]),
  },
  methods: {
    async recoverPassword() {
      const { password, confirmPassword } = this;
      const result = await useSessionStore().passwordReset({
        resetToken: this.$route.query.code,
        newPassword: password,
        confirmPassword,
      });
      if (!result) return;
      const { userId, token } = result;
      useSessionStore().$patch({
        userId,
        userToken: token,
      });
      localStorage.setItem("userToken", token);
      localStorage.setItem("userId", userId);
      this.showPasswordRecoveryModal = false;
      toast.success("Password update successful");
      this.showAuthModal = true;
    },
  },
  watch: {
    showPasswordRecoveryModal(newVal) {
      this.$refs.modal.open = newVal;
    },
  },
};
</script>

<style scoped></style>
